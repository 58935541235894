import React from 'react'
import Helmet from 'react-helmet'
import Fade from 'react-reveal/Fade'
import Layout from '../components/Layout'
import {
  CenteredArrowSVG,
  CenteredDiv,
  CenteredSection,
  ContactSectionWrapper,
  ContactLink,
  HorizontalDivisionLine,
  LinkList,
  LinkListItem,
  ServicesP,
  SectionContentWrapper,
  SideBySideWrapper,
  SideBySideH1,
  SideBySideH2,
  SideBySideP,
  StyledArrowSVG,
  WorkWithUsP
} from '../components/MainPage/styled'

import ArrowSVG from '../components/MainPage/ArrowSVG'

class Homepage extends React.Component {
  render() {
    const siteTitle = 'HedgesDigital'

    return (
      <Layout>
        <Helmet title={siteTitle}/>
        <CenteredSection id="introduction" height={50}>
          <Fade bottom>
            <SideBySideWrapper>
              <SideBySideH1>Our Team</SideBySideH1>
              <SideBySideP>
                Started in 2018, and Texas grown, you can count on our work to deliver results.
                From websites to apps, we teach and learn from each other everyday, with growth based on trust and relationships.
              </SideBySideP>
            </SideBySideWrapper>
          </Fade>
        </CenteredSection>
        <CenteredSection id="whyus" height={30}>
          <Fade bottom>
            <SideBySideWrapper marginBottom={10}>          
              <SideBySideH1>Why Us?</SideBySideH1>
              <SideBySideP>
                At Hedges Digital, our business is your business. We like to create a common goal with our clients 
                with the intent to achieve it as a team. We learn the in's and out's of our clients' businesses
                to create a deep understanding of the work we do.
              </SideBySideP>
            </SideBySideWrapper>          
          </Fade>
        </CenteredSection>
        <CenteredSection id="services" height={100}>
          <Fade>
            <SectionContentWrapper marginBottom={10}>
              <div>
                <h1>Services</h1>
                <ServicesP>
                  Hedges Digital specializes in web technoligies. Started in 2018, we offer a variety of services to help your business. 
                </ServicesP>
              </div>
              <HorizontalDivisionLine marginBottom={3} marginTop={3}/>
              <div>
                <SideBySideWrapper>      
                  <SideBySideH2>Application Development</SideBySideH2>
                  <SideBySideP>
                    Have an idea for an application? Want to build a product for your business? 
                    We work with our clients to understand their true business needs with constant 
                    feedback, product iteration and innovation.
                  </SideBySideP>
                </SideBySideWrapper>
              </div>
              <HorizontalDivisionLine marginBottom={3} marginTop={3}/>
              <div>
                <SideBySideWrapper>      
                  <SideBySideH2>Static Website Development</SideBySideH2>
                  <SideBySideP>
                    A website is a tool for business. In the world today, without an effective website, 
                      your business can suffer. We offer custom made, quality, seo and user friendly sites that
                      your users will love.
                  </SideBySideP>
                </SideBySideWrapper>     
              </div>
              <HorizontalDivisionLine marginBottom={3} marginTop={3}/>              
            </SectionContentWrapper>
          </Fade>
        </CenteredSection>
        <CenteredSection id="contact" height={50}>
          <Fade>
            <SectionContentWrapper marginBottom={10}>
              <ContactSectionWrapper>
                <h1>Work with us</h1>
                <WorkWithUsP>Click a link and fill out the form.</WorkWithUsP>
              </ContactSectionWrapper>
              <ContactSectionWrapper>
                <LinkList className="actions uniform">
                  <LinkListItem>
                    <CenteredArrowSVG>
                      <StyledArrowSVG>
                        <ArrowSVG/>
                      </StyledArrowSVG>
                      <ContactLink href="https://goo.gl/forms/VNXQ5nX4Ty3gyvBv1" target="_blank" className="button special">
                        I have a project in mind.
                      </ContactLink>
                    </CenteredArrowSVG>
                  </LinkListItem>
                  <LinkListItem>
                    <CenteredArrowSVG>
                      <StyledArrowSVG>
                        <ArrowSVG/>
                      </StyledArrowSVG>
                    <ContactLink href="https://goo.gl/forms/DTWStcYpyj8iQVlc2" target="_blank" className="button">
                      I want to ask you some questions.
                    </ContactLink>
                  </CenteredArrowSVG>
                  </LinkListItem>
                </LinkList>
              </ContactSectionWrapper>
            </SectionContentWrapper>
          </Fade>
        </CenteredSection>
      </Layout>
    )
  }
}

export default Homepage

// export const pageQuery = graphql`
//   query {
//     logo: file(relativePath: { regex: "/logo_white.png/" }) {
//       childImageSharp {
//         fluid(maxWidth: 300) {
// 					src
//         }
//       }
//     }
//   }
// `
